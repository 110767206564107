.playlistModal {
  font-family: "Inter";
}

.playlistModal_playlist_title {
  margin-top: 0.2rem;
}

.playlistModal_button {
  justify-content: space-evenly;
}
.playlistModal_checked {
  margin-left: 1rem;
}
.playlistModal .modal-content{
  border-radius: 2rem;
  width: 22rem;
}
.playlistModal .MuiFormControlLabel-root{
  height: 2rem;
}
.playlistModal .MuiFormControlLabel-label{
  font-size: 14px;
}