.eDetailingCard_title {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 600;
  height: 6.5rem;
  padding: 15px;
}

.eDetailingCard_title a {
  text-decoration: none;
  color: black;
}

/* .eDetailingCard_card {
  height: 14.625rem;
} */
.eDetailingCard_title svg {
  margin-right: 5px;
}

a {
  text-decoration: none;
}
