.Campaign_linkedTitle {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.Campaign_date {
  font-size: 1rem;
  font-weight: 700;
}
.Campaign_address {
  font-size: 1rem;
  font-weight: 700;
  float: right;
}
.Campaign_Next_Button button{
  width: 30rem;
}

/* .carousel-item {
  transition: 0.11s !important;
} */

.carousel-root img {
  max-height: 25rem;
}
/* .carousel-inner {
  overflow: visible;
} */

.carousel-inner > .carousel-item {
  /* float: none;
  transition: 0s !important; */
  /* display: block !important; */
}
/* .carousel-item {
  transition: -webkit-transform 0.5s ease !important;
  transition: transform 0.5s ease !important;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease !important;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
} */

.campaign_callButton {
  color: #2E3192 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  width: 100%;
  box-shadow: none !important;
  border: 1px solid #2E3192 !important;
  border-radius: 20px !important;
  /* border: 1px solid #2E3192; */
  /* background-image: linear-gradient(4deg, #FFE53B 0%, #FF2525 30%); */
}
/* .mark_button:focus {
  box-shadow: none !important;
  border: 1px solid #2E3192 !important;
} */
