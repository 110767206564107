.myMr_box {
  display: flex;
  flex-wrap: wrap;
  /* margin-left: 2px !important;
  margin-right: 2px !important; */
}

.myMrCard {
  margin: 14px !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .myMrCard {
    width: calc(50% - 20px) !important;
    margin: 10px !important;
  }
}

.myMrPharm_Name {
  justify-content: space-between;
  display: flex;
  color: black;
}

.myMrPharm_NameTitle {
  font-weight: 600;
  font-size: 20px;
  font-family: GilroyBold;
}

.myMrPharm_lastVisit {
  color: gray;
  font-size: 13px;
  margin-top: 2px;
  font-style: italic;
}

.myMrPharm_Post {
  color: black;
  font-size: 14px;
  margin-top: 3px;
  font-family: GilroyMedium;
}

.myMrCard {
  width: 23%;
  box-shadow: 0 1px 1px rgba(-5, 122, 218, 0.5);
}

@media only screen and (max-width: 847px) {
  .myMrCard {
    width: 31%;
    box-shadow: 0 1px 1px rgba(-5, 122, 218, 0.5);
  }
}

@media only screen and (max-width: 1024px) {
  .myMrPharm_Post {
    font-size: 13px;
  }

  .myMrPharm_NameTitle {
    font-weight: 600;
    font-size: 14px;
  }
}

@media only screen and (max-width: 733px) {
  .myMrCard {
    width: 47%;
    box-shadow: 0 1px 1px rgba(-5, 122, 218, 0.5);
  }
}

@media only screen and (max-width: 581px) {
  .myMrCard {
    width: 100%;
    box-shadow: 0 1px 1px rgba(-5, 122, 218, 0.5);
  }
}

@media only screen and (max-width: 600px) {
  .mrsearch {
    width: 90% !important;
  }
}

@media (min-width: 1026px) and (max-width: 1428px) {
  .myMrCard {
    width: 47%;
    float: left;
  }
}

@media (min-width: 582px) and (max-width: 766px) {
  .myMrCard {
    width: 45%;
    float: left;
  }
}

/* .myMrCard {
   margin: 10px !important;
}   */



