.signup_container {
  padding: 3rem 5rem 3rem 5rem;
  text-align: center;

  display: flex;
  /* font-size: 1.2rem; */
  flex-direction: column;
  padding: auto;
}
.signup_form {
  text-align: center;
}

.signUp_textfield {
  margin-top: 2rem !important;
  width: 55%;
  /* font-size: 2rem; */
}
.signUp_textfield input {
  height: 1em;
}

.signUp_textfield .MuiOutlinedInput-root {
  /* font-size: 2rem; */
  margin-top: 0.4rem;
  font-family: "Inter", sans-serif !important;
}

.signUp_textfield .MuiInputLabel-root {
  color: black !important;
  /* font-size: 1.674rem; */
  font-family: "Inter", sans-serif !important;
}
.signUp_radiofield {
  margin-top: 2rem !important;
  /* font-size: 2rem; */
  /* display: flex !important; */
  flex-direction: row !important;
  align-items: center;
  font-family: "Inter", sans-serif !important;
  width: 100%;
}

.signUp_radiofield .MuiFormGroup-row {
  margin-left: 2rem;
  font-family: "Inter", sans-serif;
}

.signUp_radiofield .MuiFormLabel-root {
  font-size: 1.3rem;

  place-self: center;

  font-weight: 400;
  font-family: "Inter", sans-serif !important;
}

.signUp_radiofield .MuiInputLabel-root {
  /* font-size: 1.5rem; */
  font-family: "Inter", sans-serif !important;
}

.signUp_radiofield .MuiFormControlLabel-root span {
  /* font-size: 1rem; */
  font-family: "Inter", sans-serif !important;
}

.signUp_radiofield .MuiFormControlLabel-root span .MuiSvgIcon-root {
  /* font-size: 2rem; */
  color: #2E3192 !important;
  font-family: "Inter", sans-serif !important;
}

.signup_btn {
  /* margin-top: 2rem; */
  text-align: center;
  justify-content: center;
  font-family: "Inter", sans-serif !important;
}

.signup_btn .MuiButton-root {
  text-align: center;
  width: 36%;
  /* font-size: 1rem; */
  font-family: "Inter", sans-serif !important;
  height: 3rem;
}

.form_footer {
  text-align: center;
  margin-top: 2rem;
  font-family: "Inter", sans-serif !important;
  font-size: 1rem;
}

.form_footer > span {
  color: red;
}

@media only screen and (max-width: 768px) {
  .signup_container {
    padding: 3rem 0rem 3rem 0rem;
  }
  .signUp_textfield {
    width: 80% !important;
  }
  .signUp_textfield input {
    height: 0.7rem;
  }
  .signUp_radiofield {
    width: 80%;
  }
  .signUp_radiofield .MuiFormLabel-root {
    font-size: 1.2rem;
  }
  .signUp_radiofield .MuiFormControlLabel-root span {
    font-size: 1rem;
    padding: 2px;
  }
  .signUp_textfield .MuiInputLabel-root {
    font-size: 1.5rem;
  }
  .signUp_radiofield label {
    margin-bottom: 0rem !important;
  }
  .signup_btn {
    margin-top: 1rem !important;
  }
}
@media only screen and (min-width: 768px) {
  .signup_btn {
    margin-top: 1rem;
  }
}
