.module_mainHeading {
  text-align: -webkit-center;
}
.module_heading {
  width: 70%;
  text-align: center;
  border-bottom: 1px solid #e2e2e2;
  line-height: 0.1em;
  margin: 0 auto;
}
.module_heading span {
  background: #fff;
  padding: 8px 34px;
  border: 1px solid #d6d6d6;
  font-size: 33px;
  border-radius: 40px;
  width: fit-content;
}
.new-icons .col {
  border: 1px solid #f3f3f3;
  padding: 10px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-boxme{
  cursor: pointer;
}
.icon-boxme img {
  height: 50px;
  margin-bottom: 20px;
}
.icon-boxme h5 {
  font-size: 16px;
}
.module_link {
  text-decoration: none;
  color: black;
}
.new-icons{
    justify-content: center;
}