/* .customers_search {
  text-align: -webkit-center;
} */
.customerFilter .MuiFormControlLabel-label{
  font-size: 14px;
}
.customerFilter .MuiFormControlLabel-root{
  height: 2rem;
}
.doctor360Modal_survey .modal-content{
  border-radius: 2rem;
}

@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .customersProfile .doctorCard_details{
    padding-left: 15px !important;
  }
  .customersProfile .card-body {
    padding-left: 3px !important;
  }

}
