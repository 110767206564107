.TabletCardInner {
    padding: 5px;
    margin-bottom: 15px;
  }
  .TabletCard {
    margin: 2rem;
  }
  .TabbletDetail {
    display: flex;
    flex-direction: column;
  }
  .TabletPrice {
    display: flex;
    flex-direction: row;
  }
  .IncrementCounter {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px;
    align-items: end;
  }

  .TabbletDetail p{
    color: gray;
  }
  .TabletCardInner p{
    color: gray;
  }
  .input-group input{
    width: 3rem;
    /* text-align: center; */
    border: 1px solid;
    border-radius: 10px;
  }

  .btn:focus {
    border-color: transparent !important;
  }