 .col{
  width: 12.5rem;
  text-align: left;
  padding: 12px 5px;
  text-align: center;
  border: none;
  font-size: 0.9rem;
  white-space: nowrap;
}
@media only screen and (max-width: 576px) {
  .prescriber_ladder_list .col{
    white-space: unset;
  }
.col{
  padding: 9px 6px;
    text-align: center;
    border: none;
    font-size: 10px;
    white-space: nowrap;
}
}
.col {
  border-collapse: collapse;
  margin: 0 20px;
}
.col {
  border-bottom: 1px solid lightgray;
}

.col {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border: none;
  margin-left: 5px;
  text-align: center;
}

.initial {
  display: inline-block;
  width: 20px;
  text-align: center;
  height: 20px;
  background: orange;
  font-weight: bold;
  border-radius: 50%;
}
