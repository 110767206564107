.CommonDropdown:focus{
    box-shadow: none !important;
}
.CommonDropdown{
    font-size: 14px;
    color: '#2E3192';
}
.form-control:focus{
    border-color: none;
    box-shadow: none;
}