.SyncData .SyncData_Btn, .SyncData .SyncData_Btn:active, .SyncData .SyncData_Btn:hover, .SyncData .SyncData_Btn:focus{
    height: 3rem;
    font-size: 1rem;
    width: 100%;
    background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,161,224,1) 0%, rgba(0,185,224,1) 100%);
    font-weight: bold;
    border: 0;
    box-shadow: none !important;
}
.SyncData_icon{
    margin-left: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    font-weight: bold;
}