.campaignsLeft_image img {
  width: 100%;
  height: 35vh;
  border-radius: 10px;
}
.campaignsLeft_description {
  width: 47vw;
}

.campaignLeft_timeAndLocation {
  color: #479747;
}
