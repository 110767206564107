.successButton{
  width: 85%;
  height: 2rem;
  background: rgb(2,0,36);
  background: linear-gradient(90deg,#00a87e,#01d4a0) !important;
  color: white;
  font-size: 12px;
  border: 0;
  border-radius: 5px;
  font-weight: 600;
  padding: 0;
  font-size: 14px !important;
  box-shadow: none !important;
  border: 0 !important;
}
.successButton:active, .successButton:hover, .successButton:focus {
  box-shadow: none !important;
  color: white;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,161,224,1) 0%, rgba(0,185,224,1) 100%);
}
.common_button{
  color: white !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border: 0 !important;
    background: linear-gradient(90deg,#00a87e,#01d4a0) !important;
    border-radius: 20px !important;
    /* background-image: linear-gradient(4deg, #FFE53B 0%, #FF2525 30%); */
  }
  .common_button:focus{
    box-shadow: none !important;
  }