.addNewVisit_timePicker .MuiTypography-root .MuiPickersToolbar-root {
  display: none;
}

.timePicker_clock .MuiClock-root {
  justify-content: flex-start;
  margin: 0;
}

.dateAndTime_time select {
  width: 5rem;
  display: inline-block;
  height: 2rem;
  margin-left: 1rem;
  font-size: 0.8;
  margin-bottom: 0.5rem;
  padding-top: 0;
}
.RescheduleModal .modal-dialog{
  max-width: 25rem;
}

.RescheduleModal .modal-content{
border-radius: 2rem;
}