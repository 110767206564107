.hamburgerMenu_container {
  transition: 850ms;
  position: fixed;
  z-index: 2;
  background: white;
  height: 45.5rem;
  width: 30rem;
  box-shadow: 2px 0px 0px rgb(0 0 0 / 50%);
}

.hamburgerMenu {
  margin: 0 2rem;
  font-family: inter;
  color: #5d5d5d;
  margin-bottom: 6rem;
  font-weight: 600;
  font-family: 'GilroySemibold';
}

.hamburgerMenu_link:hover {
  color: #2E3192 !important;
}

.hamburgerMenu_icon {
  margin-right: 1.5rem;
  width: 1.2rem;
  height: 1.3rem;
}
.hamburgerMenu_profile_box {
  display: table-cell;
  /* vertical-align: middle; */
  text-align-last: center;
  width: 150rem;
}



.hamburgerMenu_profile {
  width: 8rem;
  margin-top: 0;
  height: 8rem;
  border-radius: 50%;
  border: 4px solid #348f00;
}
.hamburgerMenu_profile_box p {
  margin: 0;
}
.offcanvas-backdrop.show{
  width: 100%;
  height: 100%;
}
.hamburgerMenu_link , .hamburgerMenu_link:hover{
  text-decoration: none;
  color: #5d5d5d;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
}


.hamburgerMenu_header {
  display: flex;
  justify-content: end;
}



