.home_header {
  position: sticky;
  top: 0px;
}
.card {
  background: #fffafa;
  border-radius: 10px;
  border: 1px solid #e9ecef !important;
}


@media screen and (min-width: 552px) and (max-width: 766px) {
  .profileImg {
    width: 4rem !important; 
    height: 4rem !important; 
    justify-content: center !important;
  }
}

@media screen and (min-width: 552px) and (max-width: 766px) {
  .profile_font {
    height: 19px !important; 
  }
}

@media screen and (min-width: 992px) and (max-width: 1500px) {
  .custom-col {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .full-width-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 990px) {
  .custom-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .full-width-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  
}




@media (min-width: 768px) {
  .col-lg-4 .Agenda_back {
    height: 175px; 
  }
}

@media (max-width: 767px) {
  .col-12 .Agenda_back {
    height: auto; 
  }
}



.pie_chart {
  height: auto;
  width: 300px;
  flex: 30%;
  padding: 20px;
  margin: 0 20px 0px 0px;
}

.quote {
  display: flex;
  text-align: center;
  background-color: #e55246;
  /* background-image: linear-gradient(to left, rgb(158 126 217), rgb(142, 45, 277)); */
  color: white;
  box-shadow: 10px 10px 5px 0 rgb(0 0 0 / 0.2);
  border-radius: 10px;
  padding: 10px;
  font-size: 1.5rem;
  margin: 1rem;
  width: -webkit-fill-available;
  flex: 90%;
}

.name_container {
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-left: 20px;
  background: linear-gradient(
    to right,
    #7953cd 20%,
    #00affa 30%,
    #0190cd 70%,
    #764ada 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 500% auto;
  animation: textShine 5s ease-in-out infinite alternate;
}

.name {
  font-weight: 900;
  color: #007ea7;
}
.home_info {
  display: flex;
}
.dailyAgenda {
  margin: 0 20px 0 20px;
  flex: 70%;
}

.home_Bar_chart {
  margin: 0 20px 6rem 20px;
}

.home_MutiAxis {
  margin: 0 20px 25px 20px;
}

.bar_chart {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.home_Reminder {
  display: flex;
  margin-top: 20px;
}

.birthday_reminder {
  flex: 30%;
  padding: 20px;
  background-image: url("./../assets/images/birthday.jpg");
}

.prescriber_ladder {
  width: 100%;
  margin: 0 20px 50px 0px;

  flex: 70%;
}

.home_rcpaTrendChart {
  height: 23rem !important;
}

.home_Welcome {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
}
.stop_working {
  text-align: center;
  background: #ec6866;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  width: 8rem;
  height: 6.375rem;
}

.pie_chart > div > div {
  position: static !important;
}

@media only screen and (max-width: 835px) {
  .home_info {
    flex-wrap: wrap;
  }
  .home_Reminder {
    flex-wrap: wrap;
  }
  .pie_chart {
    margin: 0.625rem 1rem 0 1.25rem;
    height: auto;
  }
  .dailyAgenda {
    padding-bottom: 15px;
    margin: 0 1rem 0 1.25rem;
  }
  .prescriber_ladder {
    margin: 0 20px 25px 20px;
    padding-bottom: 15px;
  }
  .birthday_reminder {
    margin-bottom: 1.25rem;
  }
  .home_Bar_chart {
    margin: 0 20px 6rem 20px;
  }
}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@media only screen and (max-width: 576px) {
  .name_container {
    font-size: 1.3rem;
  }
}
.startDay {
  color: white !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: 0 !important;
  border-radius: 20px !important;
  background-color:  #021B79 !important;
}
.Agenda_back {
  background-image: url("./../assets/images/pastel-yellow-background-1sude.jpg");
  font-family: "GilroySemibold";
}
@media only screen and (max-width: 1180px) {
  .home_modulesCard {
    min-height: 9.375rem;
  }
}

@media only screen and (max-width: 1154px) {
  .home_rcpaTrendChart {
    min-height: 24.188rem !important;
  }
}
@media only screen and (max-width: 425px) {
  .home_rcpaTrendChart {
    height: 10rem !important;
    min-height: 15rem !important;
    padding: 0;
  }
  .report_padding{
    padding: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 989px) {
  .Agenda_back{
    height: 12rem !important;
  }
}

@media only screen and (max-width: 759px) {
  .prescriber_mobile{
    display: none;
  }
  .profileImg{
    width: 5rem !important; 
    height: 5rem !important;
  }
  .profile_font{
    font-size: 12px !important;
  }
  .Profile_card{
    height: 13rem !important;
  }
}
.back_button{
  color: white;
    background-color: #00a87e;
    height: 2.2rem !important;
    width: 2.2rem !important;
    margin-top: -0.3rem;
    border-radius: 50%;
    padding: 2px;
}
.profile_font{
  font-size: 14px;
  font-family: GilroyMedium;
}
.profileImg{
    width: 7rem;
    height: 7rem;
    border-radius: 15px;
}






@media (max-width: 768px) {
  .col-12.col-md-12.col-lg-4.col-sm-12 {
    margin-bottom: 10px;
  }
}

.row.m-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
}

.CommonButton {
  padding: 8px 16px; 
  font-size: 14px; 
}

.birthday_reminder {
  height: 11rem;
  flex: 30%;
  padding: 20px;
  background-image: url("./../assets/images/birthday.jpg");
  display: flex; 
  justify-content: center; 
  align-items: center; 
}




