.doctorName_profile {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  border: 2px solid #2E3192;
  padding: 2px;
}

.doctorName_drInformation p {
  margin: 0;
  font-size: 12px;
  font-family: "Inter";
  color: black;
}
.doctorCard_details {
  padding-left: 1.2rem;
}
.doctorName_doctorName {
  font-size: 1rem !important;
  font-family: "Poppins" !important;
  margin-bottom: 4px !important;
  font-weight: 600;
}
.doctorName_button {
  width: 65%;
  height: 2rem;
  margin-top: 1rem;
  background: #7a50ee;
  color: white;
  font-size: 12px;
  border: 0;
  border-radius: 5px;
  font-weight: 600;
  padding: 0;
}
.doctorName_button:active,
.doctorName_button:hover {
  width: 65%;
  height: 2rem;
  margin-top: 1rem;
  background: #7a50ee;
  color: white;
  font-size: 12px;
  border: 0;
  border-radius: 5px;
  font-weight: 600;
  padding: 0;
}

@media only screen and (max-width: 576px) {
  .doctorName_button {
    width: 100%;
  }
}

.DoctorPharm_Name {
  justify-content: space-between;
  display: flex;
  color: black;
}
.DoctorPharm_NameTitle {
  font-weight: 600;
  font-size: 18px;
}
.DoctorPharm_badge {
  font-size: 13px;
  background: orange;
  padding: 3px 10px;
  border-radius: 15px;
  color: white;
}
.DoctorPharm_lastVisit {
  color: gray;
  font-size: 13px;
  margin-top: 2px;
  font-style: italic;
}
.DoctorPharm_badge2 {
  font-size: 12px;
  color: darkblue;
  margin-top: 4px;
  background: lightblue;
  padding: 2px 5px;
  border-radius: 15px;
  margin-right: 7px;
}
.DoctorPharm_Post {
  color: black;
  font-size: 14px;
  margin-top: 3px;
}
.DoctorCard {
  min-height: 10.25rem;
  box-shadow: 0 1px 1px #00a87e;
}
.DoctorPharm_reschedule,
.DoctorPharm_reschedule:hover,
.DoctorPharm_reschedule:focus {
  background: purple !important;
  font-size: 14px;
  padding: 3px 10px;
  border: 0;
  margin-top: 10px;
  box-shadow: none !important;
  border-radius: 15px;
}
.DoctorPharm_skip,
.DoctorPharm_skip:hover,
.DoctorPharm_skip:focus {
  background: #2E3192 !important;
  font-size: 14px;
  padding: 3px 10px;
  border: 0;
  margin-top: 10px;
  box-shadow: none !important;
  border-radius: 15px;
  margin-left: 5.5px
}

@media only screen and (max-width: 1386px) {
  .doctor360_module_card {
    min-height: 9.813rem;
  }

  .doctor360_dcr {
    min-height: 9.813rem;
  }
}
@media (max-width: 411px){
  .DoctorPharm_skip{
    margin-left: 0 !important;
  }
}
