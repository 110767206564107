.splashScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #fff;
}

.splashScreen_logo {
  width: 12rem;
  height: 12rem;
  color: white;
  border-color: white;
}
