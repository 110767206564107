.CreateMTP_table td,
.CreateMTP_table th {
  padding-left: 10px;
  padding-right: 10px;
}
.CreateMTP_table input,
.CreateMTP_table:focus-visible {
  width: 13rem;
  padding: 0 5px;
  border-radius: 6px;
  border-color: aliceblue !important;
  height: 2.2rem;
}
.delete_icon {
  height: 2rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 161, 224, 1) 0%,
    rgba(0, 185, 224, 1) 100%
  );
  color: white;
  border: 0;
  border-radius: 5px;
  /* font-weight: 600; */
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
