.singleExpense_expense {
  align-items: center;
}
.expenses_outer {
  font-weight: 500;
}
.singleExpense_expense div .MuiBox-root .MuiTextField-root {
  width: 100%;
  margin: 0 !important;
}

.singleExpense_successButton {
  height: 2rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 161, 224, 1) 0%,
    rgba(0, 185, 224, 1) 100%
  );
  color: white;
  font-size: 12px;
  border: 0;
  border-radius: 5px;
  font-weight: 600;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  text-wrap: nowrap;
}

#claim_recieipt_file_input {
  display: none;
  opacity: 0;
}
.button_expense {
  text-align: center;
}

.singleClaim_addClaim {
  margin-right: 5rem;
  text-align: right;
}

.singleClaim_addClaim button {
  width: 15rem;
}
.singleClaim_copy {
  height: 2rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 161, 224, 1) 0%,
    rgba(0, 185, 224, 1) 100%
  );
  color: white;
  border: 0;
  border-radius: 5px;
  /* font-weight: 600; */
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.singleClaim_claimtype select {
  border: #2e3192 0.5px solid;
}

@media only screen and (max-width: 400px) {
  .successButton {
    font-size: 9px !important;
    width: 93% !important;
  }
}
@media only screen and (max-width: 500px) and (min-width: 400px) {
  .successButton {
    font-size: 10px !important;
    width: 93% !important;
  }
}

@media only screen and (max-width: 850px) and (min-width: 750px) {
  .singleExpense_successButton {
    font-size: 9px !important;
  }
}
@media only screen and (max-width: 550px) {
  .button_expense {
    margin-bottom: 1rem;
  }
}
.fileInput {
  display: none;
}
.error-border {
  border: 1px solid red;
}

.normal-border {
  border: 1px solid #ccc;
}
@media (max-width: 767px) {
  .input-spacing {
    margin-top: 1rem;
  }
}
