.myprofile_container {
  padding: 0rem 5rem 0rem 5rem;
  text-align: left;
  display: flex;
  font-size: 2rem;
  flex-direction: column;
  padding: auto;
}
.myprofile_form {
  text-align: center;
  padding: 0 2rem; /* Adjust padding as needed */
  width: 100%;
}
.myprofile_edit {
  margin-top: 1rem;
  text-align: right;
}
.myprofile_image {
  text-align: center;
}
.myprofile_image > img {
  height: 14.75rem;
  border-radius: 50%;
  border: 1px solid #00a87e; 

}
.myProfile_textfield {
  margin-top: 2rem !important;
  width: 100%;
}

.myProfile_textfield .MuiOutlinedInput-root {
  font-family: "Inter" !important;
}

.myProfile_textfield .MuiInputLabel-root {
  color: black !important;
  font-family: "Inter" !important;
  line-height: 1rem;
}
.myprofile_form .MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}
.myProfile_radiofield {
  margin-top: 2rem !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  font-family: "Inter" !important;
}

.myProfile_radiofield .MuiFormGroup-row {
  margin-left: 2rem;
  font-family: "Inter" !important;
}

.myProfile_radiofield .MuiFormLabel-root {
  font-weight: 400;
  font-family: "Inter" !important;
}

.myProfile_radiofield .MuiInputLabel-root {
  font-family: "Inter" !important;
}

.myProfile_radiofield .MuiFormControlLabel-root span {
  font-family: "Inter" !important;
}

.myProfile_radiofield .MuiFormControlLabel-root span .MuiSvgIcon-root {
  color: #2E3192 !important;
  font-family: "Inter" !important;
}
.myprofile Button {
  width: 44% !important;
  background-color: #2E3192;
}
@media (max-width:554px){
  .myprofile_image > img{
    height: 9.75rem;
    width: 9.75rem;
  }
  .photo-icon{
    margin-top: 6rem !important;
    margin-left:1rem;
  }
}
.photo-icon{
  margin-top: 10rem;
  margin-left:-2.5rem;
}
.inputStyle {
  border: none;
  border-bottom: 1px solid black;
  margin-bottom: 25px;
  padding: 5px;
  width: 80%;
  outline: none; /* Remove focus outline */
  flex: 1;
  margin-right: 10px; 
  width: 100%; /* Ensure input fields stretch to container width */
  max-width: 300px;
}

@media (max-width: 554px) {
  .inputStyle {
    width: 90%; /* Adjust width for smaller screens */
  }
}


.inputStyle:hover {
  background-color: transparent; /* Remove hover background color */
}

.inputStyle:focus {
  border-bottom-color: green; /* Change bottom border color on focus */
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items to center horizontally */
  width: 100%; /* Ensure container stretches to fit screen width */
}
.eyeIcon1 {
  position: absolute;
  top: 4rem;
  right: 5rem;
}
.eyeIcon2 {
  position: absolute;
  top: 8rem;
  right: 5rem;
}
@media (max-width:554px){
  .eyeIcon2, .eyeIcon1 {
    right: 3rem;
  }
}
.passwordMsg{
  color: gray;
  font-size: 12px;
  padding: 0px 19px 0px 59px;
}
.error {
  /* border: 1px solid red; */
  border-bottom: 1px solid red;
}
.error::placeholder{
  color: red;
}
.profile-image{
 border: 1px solid #00a87e; 
 width: 15rem;
 height: 15rem;
 border-radius: 50%;
}