.custom-header {
    background-color: rgb(221, 241, 238);
    color: #00a87e;
    border-bottom: 1px solid #00a87e;
    /* padding: 5px 10px; */
  }


@media only screen and (max-width: 600px) {
    .show-more-button {
        margin-top: 5rem !important;
    }
}