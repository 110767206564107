.enterRcpa_medicineCard {
  background-color: #e9ecef;
  font-weight: bold;
  /* align-items: center; */
  box-shadow: none;
}
.enterRcpa_cancel {
  display: inline-block;
  margin-left: 1rem;
  padding-top: 1rem;
}
.enterRcpa_checkbox {
  padding-top: 1rem;
}
@media screen and (max-width:550px){
  .rcpa_cancelbtn{
    margin-top: 1rem;
    text-align: center;
  }
  .rcpa_Submitbtn{
    text-align: center;
  }
}
