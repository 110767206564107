/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@font-face {
  font-family: "GilroyBlack";
  src: url("./assets/fonts/Gilroy-Black.ttf");
}

@font-face {
  font-family: "GilroySemibold";
  src: url("./assets/fonts/Gilroy-SemiBold.ttf");
}

@font-face {
  font-family: "GilroyMedium";
  src: url("./assets/fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "GilroyBold";
  src: url("./assets/fonts/Gilroy-Bold.ttf");
}

.App {
  font-family: "Inter";
  font-size: 1rem;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: GilroyBold, sans-serif;
}

table,
td {
  font-family: Inter, sans-serif;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2E3192 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #2E3192 !important;
}

.MuiFormLabel-root {
  color: black !important;
}
