.Reports_table1 table,
.Reports_table1 th,
.Reports_table1 td {
  border: 1px solid black;
  border-collapse: collapse;
  font-size: 15px;
  text-align: center;
}
.Reports_table1 th {
  font-weight: 500;
}

.Reports_table1 th {
  font-weight: 500;
}

.Reports_ChartCard {
  box-shadow: 0.078rem 0.078rem 0.334rem rgba(20, 20, 20, 0.07) !important;
  border-radius: 0.433rem !important;
  height: 333px;
  text-align: center;
}

.Reports_ChartCard canvas {
  width: 100%;
  height: 100%;
}

.Report_progressBar {
  padding: 0px 90px;
}

.Report_chartBox {
  height: 50%;
  padding: 20px;
}

.Report_chartBox h1 {
  color: #088f8f;
}

.Report_chartBox h6 {
  font-weight: 500;
}

.report__headbox {
  margin: 5px 5px;
  text-align: center;
  box-shadow: 0.078rem 0.078rem 0.334rem rgba(20, 20, 20, 0.07) !important;
  border-radius: 0.433rem !important;
}

.report__headboxColor1 {
  background: #d697ff;
}

.report__headboxColor1 p {
  color: #9400d3;
}

.report__headboxColor2 {
  background: #add8e6;
}

.report__headboxColor2 p {
  color: #088f8f;
}

.report__headboxColor3 {
  background: #ffffcc;
}

.report__headboxColor3 p {
  color: #e1c16e;
}

.report__headboxColor4 {
  background: #90ee90;
}

.report__headboxColor4 p {
  color: #4cbb17;
}

.report__headboxColor5 {
  background: #ffa8b5;
}

.report__headboxColor5 p {
  color: #de3163;
}

.report__headbox p {
  margin-bottom: 0px !important;
}

.report__headbox1Title {
  font-size: 1.5rem;
  padding: 10px 0px 0px 0px;
  font-weight: 600;
}

.report__headboxMsg {
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}

@media (min-width: 426px) and (max-width: 768px) {
  .report__headboxMsg {
    font-size: 9px;
  }

  .report__headbox1Title {
    font-size: 1rem;
  }

  .Report_progressBar {
    padding: 0px 50px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .report__headboxMsg {
    font-size: 12px;
  }

  .report__headbox1Title {
    font-size: 1rem;
  }

  .Report_progressBar {
    padding: 0px 1.875rem;
  }
}

@media (min-width: 1201px) and (max-width: 1399px) {
  .report__headboxMsg {
    font-size: 12px;
  }

  .report__headbox1Title {
    font-size: 1rem;
  }

  .Report_progressBar {
    padding: 0px 3.75rem;
  }
}

.Report_progressBarText {
  position: relative;
  top: -8.5rem;
  color: rgb(76, 187, 23);
}

.Report_progressBarEntry {
  background: #00ffff;
}
@media (max-width:550px){
  .reportsMobile{
    display: none;
  }
}
