.Tabs_section .nav-tabs .nav-link {
  width: 100%;
}

.markAttendance .nav-tabs .nav-link {
  width: 100%;
}

.markAttendance_profile {
  width: 5.5rem;
  height: 5.5rem;
}

.markAttendance_drInformation p {
  margin: 0;
  font-size: 12px;
  font-family: "Inter";
}

.markAttendance_doctorName {
  font-size: 14px;
  margin-bottom: 3px;
}

.markAttendance_button {
  width: 85%;
  height: 1.5rem;
  margin-top: 1rem;
  background-image: linear-gradient(
    to right,
    #6a3093 0%,
    #a044ff 51%,
    #6a3093 100%
  );
  color: white;
  font-size: 12px;
  border: 0;
  border-radius: 10px;
  font-weight: 600;
  padding: 0;
}

.markAttendance_viewMore {
  float: right;
  margin: 1.5rem 1rem 2rem 2rem;
}

.markAttendance_check {
  font-size: 14px;
  font-family: "Inter";
}

.markAttendance.nav-tabs .nav-link:focus {
  border: 0;
  border-color: white;
}

.markAttendance button:focus {
  border: 0;
  border-color: white;
  outline: 0;
  color: whi;
}

.markAttendance .nav-item .nav-link {
  color: gray;
}

.markAttendance .nav-item .active {
  font-weight: 500 !important;
  border: 1px solid;
  /* background-color: #FAD961 !important; */
  background: #00a87e !important;
  color: white;
}

.form-check-input {
  cursor: pointer;
}
.viewMore {
  height: 2rem;
  margin-top: 3rem;
  background: none;
  color: #2e3192;
  border: 0;
}
.viewMore:active,
.viewMore:hover,
.viewMore:focus {
  box-shadow: none !important;
  background: none !important;
  color: #2e3192 !important;
}
.markAttendance .nav-item #fill-tab-example-tab-Pending {
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 0;
}
.markAttendance .nav-item #fill-tab-example-tab-Completed {
  border-bottom-right-radius: 15px;
  border-top-left-radius: 0;
  border-top-right-radius: 15px;
}
.markAttendance .nav-item #fill-tab-example-tab-Deleted {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  border-top-left-radius: 0;
}
.markAttendance .nav-item #fill-tab-example-tab-Approved {
  border-radius: 0;
}
.markAttendance .nav-item .nav-link {
  border: 1px solid #00a87e;
  background: white;
}
.markAttendance_tab .nav-fill .nav-item  {
  margin-right: 50px !important;
}
.markAttendance_tab {
  display: flex !important;
  border: 0;
}
.markAttendance_tab .li{
  margin-right: 50px !important;

  
}
@media only screen and (min-width: 786px) {
  .changeDateBtn{
    text-align: right !important;
    
  }
}
.changeDateBtn{
  margin-top: 1rem;
  text-align: center;
}
.change_date{
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
width: fit-content;
}
@media only screen and (min-width: 786px) {
.markAttendance_tabsSection{
  margin-right: 20px;
}

}

@media only screen and (max-width: 600px) {
.markAttendance .nav-item #fill-tab-example-tab-Completed {
  border-radius: 0;
}
.markAttendance .nav-item #fill-tab-example-tab-Deleted {
  border-radius: 0;
}
.markAttendance .nav-item #fill-tab-example-tab-Approved {
  border-radius: 0;
}
.markAttendance .nav-item #fill-tab-example-tab-Pending {
  border-radius: 0;
}
}