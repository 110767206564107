/* .DeviceScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.21);
  backdrop-filter: blur(5px);
  padding: 10px;
  height: 100vh;
} */

.DeviceScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 6vw;
  position: relative;
}
.DeviceScreen:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url(https://img.freepik.com/free-vector/blue-blurred-background-design_1107-117.jpg)
    no-repeat;
  filter: blur(4px);
  background-size: 100vw 100vh;
  /* makup ? */
  box-shadow: inset 0 0 0 50vw rgba(255, 255, 255, 0.2);
}
/* makup to center content */
