/* .eDetailing_search_container {
  display: flex;
  justify-content: center;
} */

.eDetailing_search {
  margin-right: 3rem !important;
  border: 2px black solid;
  width: 80%;
}

.eDetailing_search input {
  text-align: center;
  margin-right: 0rem !important;
}

.eDetailing .nav-item {
  background-color: #d3d3d347;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.eDetailing .nav-item .nav-link {
  color: gray;
}

.eDetailing .nav-item .active {
  color: #FFFFFF;
  font-weight: 500;
  background-color: #4ad84a !important;
}
.Edetailing_sort {
  border: 0;
  background: none;
}
.Edetailing_sort:active,
.Edetailing_sort:focus {
  color: black !important;
  background: none !important;
  box-shadow: none !important;
}

.Edetailing_sort.btn-info:hover,
.Edetailing_sort.btn-info:active,
.Edetailing_sort.btn-info:focus,
.Edetailing_sort.btn-info:visited {
  background-color: unset !important;
  background: none !important;
  border-color: unset !important;
}
.show > .btn-info.dropdown-toggle {
  color: black;
  background-color: unset !important;
  border-color: unset !important;
}
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: unset !important;
  background: none !important;
  color: black;
  /*  border-color: unset !important; */
}

/* .edetailing_content ul {
  width: 20%;
} */

.eDetailingCard_cardBox{
  width: 20rem;
}

@media (max-width:1024px) and (min-width:770px) {
  .eDetailingCard_cardBox {
    width: 15%;
  }
}

@media (max-width:769px) and (min-width:426px) {
  .eDetailingCard_cardBox {
      width: 20%;
    }
}

.edetailing_content li{
  margin-right: 10px;
}
@media (min-width:425px){
  .tabs_width{
    width: 25rem;
  }
}

.eDetailingContent_container {
  max-width: calc(100vw - 10px);
  padding: 0 5px;
  box-sizing: border-box;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr)); 
  gap: 1rem;
}

.eDetailingContent_data {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr)); /* 5 grid template columns */
  gap: 2rem; 
}

.eDetailingCard_cardBox {
  width: 100%;
}

@media (max-width: 1024px) and (min-width: 770px) {
  /* .eDetailingCard_cardBox {
    width: 15%;
  } */
  .eDetailingContent_data {
    grid-template-columns: repeat(4, minmax(0, 1fr)); 

  }
}

@media (max-width: 769px) and (min-width: 426px) {
  /* .eDetailingCard_cardBox {
    width: 20%;
  } */
  .eDetailingContent_data {
    grid-template-columns: repeat(3, minmax(0, 1fr)); 

  }
}
@media (max-width: 426px)and (min-width: 322px)  {
  .eDetailingContent_data {
    grid-template-columns: repeat(2, minmax(0, 1fr)); 
  }
}
@media (max-width: 321px) {
   .eDetailingCard_cardBox {
    width: 85%;
  }
  .eDetailingContent_data {
    grid-template-columns: repeat(1, minmax(0, 1fr)); 
  }
}

/* Add more media queries for responsiveness as needed */
