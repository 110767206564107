.addNewVisit_visit_type {
  margin-top: 2rem;
  text-align: -webkit-center;
}

.addNewVisit_visit_type select {
  width: 15rem;
  border: 1px black solid;
}

.addNewVisit_select_doctor_top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0rem 1rem 1rem 1rem;
}
.addNewVisit_dateTime {
  display: flex;
  align-items: center;
  margin: 0rem 1rem 1rem 1rem;
}

.addNewVisit_dateTime {
  margin-top: 2rem;
}

.calendarIcon {
  cursor: pointer;
}

.addNewVisit_buttons {
  display: flex;
  justify-content: space-evenly;
}

.addNewVisit_buttons .MuiButton-root {
  text-align: center;
  width: 15rem;
  font-family: "Poppins", sans-serif !important;
}

.addNewVisit_filterIcon {
  cursor: pointer;
}
.addNewVisit_dateTime_select{
  margin-left: 1rem;
}
.form-select{
  cursor: pointer;
}