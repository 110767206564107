.PlanMTP_leftHead {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
  font-family:GilroyMedium;
}
.PlanMTP_leftDrop {
  font-size: 14px;
}
.PlanMTP_errors{
    color: red;
    font-size: 14px;
    margin-left: 1rem;
}
.ViewMTP_input {
  width: 100%;
  color: "rgb(46, 49, 146)";
  font-size: 14px;
  background-image: none;
  padding-right: "14px";
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0.4rem;
  top: 0.4rem;
  width: auto;
}

input[type="time"] {
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0.4rem;
  top: 0.4rem;
  width: auto;
}

.planField {
  width: 100%;
  color: rgb(46, 49, 146);
  font-size: 14px;
  background-image: none;
  padding-right: 14px;
}
