.viewDailyVisitPlan_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.viewDailyVisitPlan_date {
  width: 18.75rem;
  align-self: center;
}
.viewDailyVisitPlan_date input {
  text-align: center;
}

.viewDailyVisitPlan_data {
  margin-left: 1rem;
  color: gray;
}

.viewDailyVisitPlan_paln {
  margin-top: 1rem;
}

.viewDailyVisitPlan_time {
  width: 10%;
  display: inline-block;
  color: gray;
}

.viewDailyVisitPlan_line {
  width: 80%;
  height: 1px;
  background: gray;
  display: inline-block;
  margin-bottom: 0.2rem;
}

.viewDailyVisitPlan_link {
  text-decoration: none;
  color: black;
  margin-right: 1rem;
  text-align: -webkit-right;
  font-size: 1.5rem;
}
.viewDailyVisitPlan_link button {
  margin-right: 2rem;
}

.dailyVisit_box {
  height: 3rem;
  width: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  overflow: hidden;
  border-bottom: 1px solid rgba(89, 89, 89, 0.6);
  /* text-align: center; */
}

.viewDailyVisit_time {
  height: 3rem;
  display: flex;
  color: var(--on-surface-variant-agm);
  font-size: 10px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.viewDailyVisit_day {
  /* height: 3rem; */
  /* width: 14%; */
  padding-left: 2rem;
  border-bottom: 1px solid rgba(89, 89, 89, 0.6);
  border-right: 1px solid rgba(89, 89, 89, 0.6);
  /* text-align: center; */
}

.viewDailyVisitPlan .css-cwhad8-MuiDateCalendar-root {
  width: auto;
}

.viewDailyVisitPlan {
  padding: 10px;
}

.viewDailyVisit_day .day {
  line-height: 20px;
  position: relative;
  z-index: 5;
  color: #5a5a5a;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.8px;
  margin-left: 0;
  margin-top: 8px;
  text-transform: uppercase;
  opacity: 0.8;
}

.viewDailyVisit_day .date {
  font-size: 26px;
  letter-spacing: -2.6px;
  color: #5a5a5a;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  margin-left: auto;
  margin-right: auto;
  margin-top: -8px;
  font-weight: 500;
}

.viewDailyVisit_blanktime {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid rgba(89, 89, 89, 0.6); */
  border-right: 1px solid rgba(89, 89, 89, 0.6);
}

.viewDailyVisit_activeDate {
  padding: 5px 10px 5px 7px;
  text-align: center;
  font-size: 26px;
  background-color: #2E3192;
  color: white !important;
  border-radius: 20px;
}

.dailyVisit_boxData {
  text-align: start;
  font-size: 12px;
  font-weight: 500;
  color: black;
  display: block;
  height: 100%;
  padding: 1rem 0 0 1rem;
}

.viewDailyVisitPlan_calender
  .css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
  /* padding: 5px 10px;
    background: #FFFFFF;
    box-shadow: 0.965934px 0.965934px 4.14278px rgba(20, 20, 20, 0.07);
    border-radius: 20.7326px; */
  padding-left: 5px;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: var(--on-surface);
}
@media only screen and (min-width: 760px) {
  .viewDailyVisitPlan{
    margin-top: 3rem;
  }
}