.chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Inter, sans-serif;
}

.chart > span {
  margin-top: 5px;
}

canvas {
  max-height: 300px !important;
}
