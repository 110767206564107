@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .ProfileIcon {
    height: 9rem !important;
    width: 6rem !important;
  }
  .DoctorPharm_Post span {
    font-size: 12px !important;
  }
  .CakeImg {
    height: 5rem !important;
  }
  .DCRicon {
    height: 5.2rem !important;
  }
}

@media (max-width: 767px) {
  .DoctorCardWrapper {
    margin-right: 12px; /* Adjust the margin as needed */
    margin-left: 2px; /* Adjust the margin as needed */
  }
}


.RcpaValues .card {
  padding-top: 0 !important;
  min-height: 17.3rem !important;
}
.SampleInput .card {
  padding-top: 0 !important;
  min-height: 17.3rem !important;
}
.LastDoctor .card {
  padding-top: 0 !important;
  min-height: 17.3rem !important;
}
@media (min-width:550px){
.Doctor360Profile{
  height: 11rem;
}
}

@media (max-width: 576px) {
  /* Apply right margin only on small devices (up to 576px) */
  .DoctorCardWrapper {
    margin-right: 0px; /* Adjust the margin as needed */
  }
}
