.input {
  display: flex;
  align-items: start;
}
.input_heading {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.input_body {
  margin-left: 1rem;
}

.newVisitFilterModal_specialization {
  margin-top: 1rem;
}

.newVisitFilterModal_buttons {
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
}
.NewVisitModal .modal-dialog{
  max-width: 30rem;
}
.NewVisitModal_content .MuiFormControlLabel-label{
  font-size: 14px;
}
.NewVisitModal_content .MuiCheckbox-colorPrimary{
padding: 5px;
}
.NewVisitModal .modal-content{
  border-radius: 2rem;
}