.input-col {
    text-align-last: center;
    text-align: -webkit-center;
}

.container {
    margin: 5px auto;
    max-width: calc(100vw - 10px);
    padding: 0 5px;
    box-sizing: border-box;
}

.LAC_input {
    width: 100%;
    margin: 0rem 2rem 2rem 0rem !important;
}

.reason_input {
    width: 100%;
    margin: 0rem 2rem 2rem 0rem !important;
}

.LAC_input .MuiInputBase-input {
    padding: 8px;
}

.leave-apply-select .CommonDropdown {
    width: 100%;
    margin: 0rem 2rem 2rem 0rem !important;
}

.applyButton {
    width: 100%;
    height: 2rem;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(0, 161, 224, 1) 0%,
            rgba(0, 185, 224, 1) 100%);
    color: white;
    font-size: 12px;
    border: 0;
    border-radius: 5px;
    font-weight: 600;
    padding: 0;
    font-size: 14px !important;
    box-shadow: none !important;
}

.cancelButton {
    width: 100%;
    height: 2.2rem;
    background-color: #f4d03f;
    background-image: linear-gradient(132deg, #f4d03f 0%, #16a085 100%);
    color: white;
    font-size: 12px;
    border: 0;
    border-radius: 10px;
    font-weight: 600;
    padding: 0;
}

.applyButton:focus {
    box-shadow: none;
}

@media only screen and (max-width: 576px) {
    .leave-apply-select {
        text-align: -webkit-center;
    }

    .LAC_colButton {
        text-align: -webkit-center;
        margin-bottom: 1.5rem;
        margin-top: 1rem;
    }

    .applyButton {
        margin-bottom: 1.5rem;
    }

    .reason_input {
        width: 100%;
    }

    .LAC_input {
        width: 100%;
    }

    .leave-apply-select .CommonDropdown {
        width: 100%;
    }

    .applyButton {
        width: 100%;
    }

    .cancelButton {
        width: 100%;
    }
}

.leaveButton {
    text-align: -webkit-center;
}

.react-calendar {
    width: 100% !important;
}

.leaveApply_button button {
    margin-top: 0;
    width: 85%;
}

.leaveDropdown {
    width: 100%;
    margin: 0rem 2rem 0rem 0rem !important;
}

.leaveDropdown:focus {
    box-shadow: none !important;
}

.leaveDropdown {
    font-size: 14px;
    color: '#2E3192';
}

.custom-checkbox {
    display: flex;
    justify-content: center;
    top: 3px;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    /* Controls the size of the checkbox */
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    display: inline-block;
    width: 20px;
    /* Size of the checkbox */
    height: 20px;
    /* Size of the checkbox */
    background-color: white;
    border: 2px solid #00a87e;
    border-radius: 50%;
    /* Makes the checkbox circular */
    position: relative;
    transition: background-color 0.2s ease-in-out;
}

.custom-checkbox input:checked~.checkmark {
    background-color: #00a87e;
    /* Green fill color when checked */
}

.custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked~.checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 5.5px;
    top: 2px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

/* General styles for the form */
.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    /* Space between fields */
}

.form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* Default to full width for mobile */
    margin-bottom: 10px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
    /* Align label to the left */
}

.custom-input {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    /* Make input field full width within its container */
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    text-align: left;
    /* Align error message to the left */
}

.submit-button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

/* Media query for tablets and larger devices */
@media (min-width: 768px) {
    .form-group {
        width: 48%;
        /* Set width to fit two fields per row */
    }
}

.formContainer {
    padding: 4rem;
    padding-top: 0%;
}