.survey__active {
  width: 14%;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 161, 224, 1) 0%,
    rgba(0, 185, 224, 1) 100%
  );
}

.feedback-form-container {
  margin: 5px auto;
  max-width: calc(100vw - 10px);
  padding: 0 5px;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .feedback-form-container {
    margin: 20px 10px;
    max-width: calc(100% - 20px);
  }
}

.survey__activebutton {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: 14%;
  border: none;
}

.survey__inActivebutton {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  width: 14%;
  border: none;
}

.survey__header {
  font-size: 20px;
  font-weight: 500;
}

.survey__radioButton {
  margin-right: 30px;
}

.survey__rating {
  color: #ffea00;
}

.survey__rating2 {
  color: #edeade;
}

.review svg {
  width: 2em;
  height: 2em;
}

@media only screen and (max-width: 550px) {
  .cancelBtn_mob {
    margin-top: 1rem;
  }
}

.dcr-form-errors {
  color: red;
  font-weight: 500;
  font-size: 14px;
}

.dcr_save_button {
  width: 100%;
}

.rating-container {
  display: flex;
  align-items: center;
}

.rating-container .PlanMTP_leftHead {
  margin-right: 10px;
}

.rating-container .rating-stars {
  flex-grow: 1;
}

/* @media (min-width: 992px) and (max-width: 1320px) {
  .justify-content-end {
    justify-content: flex-start !important;
  }
} */

@media only screen and (min-width: 991px) and (max-width: 1320px) {
  .tourPlanNameBtn {
    margin-right: 200px;
    margin-left: 5px;
  }
}
input[type="text"], input[type="date"],input[type="number"]{
  font-family: GilroyMedium;
}
