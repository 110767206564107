.failButton{
  width: 85%;
  height: 2rem;
  /* margin-top: 1rem; */
  background-color: #d2112c !important;
  color: white !important;
  font-size: 12px !important;
  border: 0 !important;
  border-radius: 20px !important;
  font-weight: 600 !important;
  padding: 0 !important;
  font-size: 14px !important;
  box-shadow: none !important;

}
.failButton:active, .failButton:hover, .failButton:focus {
  box-shadow: none !important;
  color: white;
  background-color: #d2112c !important;
}