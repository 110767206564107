.PlaylistComponent_bar td{
    background-color: #21D4FD;
    background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);
    font-size: 1.2rem;    
    color: white;
}
.PlaylistComponent_sharedel{
    text-align: right;
}
.PlaylistComponent_table td{
    text-align: left;
    color: black;
    margin: 0;
    border: none;
}
.PlaylistComponent_table .icon1{
    font-size: 1.7rem;
    color: #867325;

}
.PlaylistComponent_table .icon2{
    font-size: 1.7rem;
}
.PlaylistComponent_sharedel .icon2{
    font-size: 1.7rem;
}
.PlaylistComponent_play{
    padding: 10px 15px;
    border-radius: 10px;
    background: white;
    color: #8124E1;
}

.Playlist_row {
    width: 30%;
}