body{
  font-family: proxima-nova, sans-serif;
}

.header {
  justify-content: space-between;
  height: 5.5rem;
  padding: 1.5rem;
  font-style: normal;
  font-weight: 900;
  font-size: 1.5rem;
  color: #f8f8fa;
  background: linear-gradient(to left, #00a87e, #fff) !important;;
  /* border-bottom-left-radius: 35px; */
  /* border-bottom-right-radius: 35px; */
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row !important;
  position: fixed;
  width: 100%;
  z-index: 9;
  align-items: center !important;
  /* border-bottom: 1px solid black; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, .05);
  border-bottom: 1px solid #00a87e;
}
.header-logo {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 10px;
  color: white;
}
.medicine-icon {
  height: 2.5rem !important;
  width: 2.5rem !important;
  cursor: pointer;
  color: black !important;
}
.power_icon {
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  vertical-align: middle;
  background-color: #ffe53b;
  background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%);
  border-radius: 50%;
  border: 2px solid white;
}
.power-icon {
  width: 2.5rem;
  margin-top: 0.5rem;
  margin-top: -4px;
  margin-left: -1px;
  color: white;
}
.logo {
  color: #0b2447;
  position: absolute;
  top: 10px;
  left: 0;
}
.header_title {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date {
  color: black;
    letter-spacing: .42px;
    padding: 20px 16px;
    font-size: 16px;
    line-height: 1.2;
    text-decoration: none;
    display: inline-block;
    font-family: proxima-nova, sans-serif;
}
.calendar_icon {
  margin-right: 2rem;
}
.date:hover {
  cursor: pointer;
}
@media only screen and (max-width: 576px) {
  .header_left {
    margin-left: 1rem;
  }
  .header {
    padding: 0;
  }
  .date {
    margin-right: 10px;
    display: none;
  }
  .power_icon {
    margin-right: 5px;
    height: 2rem;
    width: 2rem;
  }
  .power-icon {
    width: 2rem;
    margin-top: -12px;
    margin-left: -1px;
  }
  .header_right {
    margin-right: 10px;
  }
}
.HeaderProfile {
  height: 4rem;
  margin-left: 1rem;
  width: 4rem;
  /* border-radius: 50%; */
  /* border: 1px solid white; */
  padding: 2px;
}
::-webkit-input-placeholder {
  color: rgba(#efefef, .2);
}
:-moz-placeholder {
  color: rgba(#efefef, .2);
}
::-moz-placeholder {
  color: rgba(#efefef, .2);
}
:-ms-input-placeholder {
  color: rgba(#efefef, .2);
}
.searchBAr{
  border-radius: 1rem;
    margin-right: 1rem;
    padding-left: 1rem;
    font-size: 15px;
    border: 0;
    padding: 5px 20px;
}

@media only screen and (max-width: 550px) {
  .searchBAr{
    display: none;
  }
}
