.heading {
  font-size: 35px;
  font-weight: bolder;
}
.heading_container {
  text-align: center;
  padding: 10px;
}

.semi_heading {
  margin: 5px;
  font-size: 20px;
  font-weight: 400;
  color: #9ca3af;
}
.ladder_heading{
  color: #2E3192;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid lightblue;
    background: #0dcaf026;
    height: 3rem;
}
.ladder_heading_data{
  font-size: 14px;
  height: 3rem;
  border-bottom: 1px solid lightblue;;
}

.custom-header {
  background-color: rgb(221, 241, 238);
  color: #00a87e;
  border-bottom: 1px solid #00a87e;
  /* padding: 5px 10px; */
}

