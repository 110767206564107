.commonSearch {
  margin-right: 3rem !important;
}
.commonSearch input:focus{
  box-shadow: none !important;
}
.commonSearch input {
  /* text-align: center; */
  margin-right: 0rem !important;
}
.commonSearch input::placeholder {
  color: #CCCCCC; /* Change this color as per your preference */
}