.ladder_heading{
    color: #2E3192;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 1px solid lightblue;
      background: #0dcaf026;
      height: 3rem;
  }
  .ladder_heading_data{
    font-size: 14px;
    height: 3rem;
    border-bottom: 1px solid lightblue;;
  }
  .Resolved_Staus {
    color: green;
    font-size: 15px;
    font-weight: 700;
    border-radius: 3px;
  }
  .CampaignVisit_data td p{
    color: #17cf17;
    font-weight: 500;
    padding-top: 1rem;
    margin: 0;
  }

  .CampaignVisit_Head td p{
    color: red;
    font-weight: 500;
    padding-top: 1rem;
    margin: 0;
  }

  .CampaignVisit_Head  .Resolved_Status {
    border: 2px solid #17cf17;
    border-radius: 15px;
    color: #17cf17;
    font-size: 15px;
    font-weight: 500;
  }
