.MTP_createMTP {
  float: right;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  background-color: #FFE53B;
  border-radius: 20px;
  background-image: linear-gradient(4deg, #FFE53B 0%, #FF2525 30%);
}

.MTP_mainContent {
  font-size: 14px;
  text-align: center;
}

.MTP_tableHeading {
  color: #2E3192;
  font-weight: 500;
}

.MTP_tableData {
  color: black;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.MTP_approved {
  height: 1rem;
  margin-right: 3px;
  margin-top: -3px;
}

.MTP_data {
  font-weight: 500;
  color: black;
}

@media only screen and (max-width: 576px) {
  .tourPlanNameBtn {
    text-align: left !important;
    margin-top: 1rem;
  }

  .MTP_mobileBtn {
    margin-bottom: 1rem;
  }
}

.MTP_buttons {
  margin-left: 2rem;
}

@media only screen and (max-width: 995px) {
  .MTP_buttons {
    margin-right: 0rem;
  }
}

.MTP_Delete {
  /* background: #00a87e; */
  color: #00a87e;
  height: 2rem !important;
  width: 2rem !important;
  cursor: pointer;
}

.MTP_draft {
  height: 1.2rem;
  margin-top: -3px;
}

.MTP_checkbox {
  height: 1rem;
  width: 1rem;
  margin-right: 5px !important;
}

.MTP_cross {
  height: 16px !important;
}

@media only screen and (max-width: 768px) {
  .approve_tours {
    margin-top: 3rem;
  }
}

@media only screen and (min-width: 650px) {
  .approve_tours {
    float: "right";
    margin-right: "2rem";
  }
}

.Approve_plansTab {
  width: 80%;
}

.Approve_plansTab_heading {
  text-align: -webkit-center;
}

.doctor360Modal_survey .modal-content {
  text-align: -webkit-center;
}
/* 
@media (min-width: 992px) and (max-width: 1320px) {
  .tourPlanNameBtn {
    width: 1px !important;
  }
} */