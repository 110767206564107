.birthday_title {
  font-size: 2rem  !important;
  text-align: center;
  margin-top: 20px;
}

.birthday_card {
  height: 75px; 
  overflow: hidden;
}

.no_birthday_card {
  height: 90px; 
}


.birthday_navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Adjust this value as needed */
}

.birthday_name {
  font-weight: bold;
  margin: 0;
}

.birthday_info {
  display: flex;
  align-items: center;
}

.birthday_photo {
  width: 60px; 
  height: 60px; 
  /* border-radius: 50%; */
  margin-right: 10px;
  /* border: 2px solid #edeef6;  */

}

.birthday_info {
  display: flex;
  align-items: center;
  justify-content: center; /* Add this line to horizontally center the content */

}

.left-arrow  {
  font-size: 1.2rem; 
  order: 1;
  margin-top: -62px;
  margin-bottom: 40px;
}

.right-arrow{
  order: 2;
  font-size: 1.2rem;
  margin-top: -62px;
  margin-bottom: 40px;
}

.birthday_details {
  text-align: center; /* Center align the text */
}

.arrow-icon {
  cursor: pointer;
}

.birthday_navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.birthday {
  font-size: 14px;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  font-family: Inter, sans-serif;
  padding: 20px;
  margin: 1rem;
  border-radius: 2rem;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,161,224,1) 0%, rgba(0,185,224,1) 100%);
  background-size: 400% 400%;
  border: none;
  -webkit-animation: BirthdayGradient 31s ease infinite;
  -moz-animation: BirthdayGradient 31s ease infinite;
  animation: BirthdayGradient 31s ease infinite;
}

.birthday_conainer {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.arrow-container {
  display: flex;
  align-items: center;
}
.cake_icon {
  height: 50px;
  width: 50px;
}

.arrow-icon {
  font-size: 15px; /* Adjust this value as needed */
  cursor: pointer;
}

.birthday_date {
  font-weight: bold;
  font-size: 12px;
}

@-webkit-keyframes BirthdayGradient {
  0%{background-position:0% 63%}
  50%{background-position:100% 38%}
  100%{background-position:0% 63%}
}
@-moz-keyframes BirthdayGradient {
  0%{background-position:0% 63%}
  50%{background-position:100% 38%}
  100%{background-position:0% 63%}
}
@keyframes BirthdayGradient {
  0%{background-position:0% 63%}
  50%{background-position:100% 38%}
  100%{background-position:0% 63%}
}
.birthday_day{
  width: 100%;
    border-radius: 20px;
    height: 2.5rem;
    background-color: #e55246;
    /* background-image: linear-gradient(359deg, #FFE53B 0%, #FF2525 74%); */
    color: white;
    border: 0;
}
@media only screen and (max-width: 576px) {
  .birthday_title{
    font-size: 1.2rem;
  }
  .birthday{
    font-size: 10px;
    padding: 10px 20px;
    border-radius: 1rem;
    margin: 0 0 1rem 0;
  }
  .birthday_date{
    font-size: 10px;
  }
  .birthday_day{
    height: 2rem;
  }
  .birthday_title {
    font-size: 1.8rem !important;
  }
}

.birthday_container {
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center; 
}

.birthday_title {
  font-size: 2rem;
  text-align: center;
}
.birthday_card .birthday_name{
  color:  black !important;
  font-family: GilroyBold;
  font-size: 20px;
}

.birthday_card {
  height: 100%; 
  overflow: hidden;
}