.login_page {
  margin-top: 7rem !important;
  text-align: -webkit-center;
}

.forgetpassword {
  margin-top: 0px;
  margin-bottom: 10px;
  margin-right: 15px;
  text-align: right;
  color: darkblue;
}

.forgot-password {
  color: green;
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password:hover {
  color: darkblue;
}

.logo_box {
  width: 10rem;
  /* background-image: linear-gradient(41deg, #510854 0%, #2BD2FF 52%, #55ba81 84%); */
  color: white;
  border-radius: 50%;
  height: 10rem;
  display: table-cell;
  vertical-align: middle;
}

@media (max-width: 425px) {
  .logo_box {
    width: 7rem;
    margin-top: 5rem;
    height: 7rem;
  }
  .login_input {
    margin-top: 2rem !important;
  }
}

.login_input {
  width: 100%;
  margin-top: 2rem !important;
}

.login_content {
  width: 60%;
  text-align: center;
  font-family: "Inter";
  align-self: center;
}

.login_sub_text {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 13px;
  text-align: left;
}

.login_text {
  margin-bottom: 0;
  font-weight: 500;
  text-align: left;
}

.OTP_forgot {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.signIn_button {
  width: 25rem;
  height: 2rem;
  margin-top: 1rem;
  background-image: linear-gradient(
    to right,
    #6a3093 0%,
    #a044ff 51%,
    #6a3093 100%
  );
  color: white !important;
  font-size: 1rem;
  border: 0;
  border-radius: 10px;
  font-weight: 600;
}

.signIn_button:hover,
.signIn_button:active,
.signIn_button:focus {
  color: white !important;
  box-shadow: none !important;
}

@media screen and (max-width: 768px) {
  .login_content {
    width: 80%;
  }

  .signIn_button {
    width: 90%;
  }
}

.left-right-divider {
  display: flex;
  align-items: center;
  text-align: center;
}

.login_page .pt-2 {
  padding-top: 16px !important;
}

.login_page .pb-3 {
  padding-bottom: 16px !important;
}

.left-right-divider::before,
.left-right-divider::after {
  height: 1px;
  width: auto;
  background-color: rgb(0 0 0 / 0.35);
  --tw-content: "";
  content: var(--tw-content);
  flex-grow: 1;
}

.text-sm {
  font-size: 14px;
}

.login_page .px-2 {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 1rem !important;
}

.google-button {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.6) !important;
  /* margin-left: 16px; */
  width: 20rem;
  height: 2.5rem;
  border-radius: 28px !important;
  border-color: #000 !important;
}

.signin-span {
  margin-left: 22px;
}

.login-button {
  width: 20rem;
  height: 2.5rem;
  border-radius: 28px !important;
}

.google-button:hover {
  background-color: rgba(207, 207, 207, 0.25);
  color: rgba(0, 0, 0, 0.75);
}

.new-user-button {
  background-color: transparent;
  padding: 0;
  border: 1px solid transparent;
  color: #0a66c2 !important;
  text-decoration: none !important;
}

.new-user-message {
  color: rgba(0, 0, 0, 0.9);
  margin-top: 20px;
  text-align: center;
}
@media screen and (max-width: 416px) {
  .google-button {
    width: 100%;
  }
  .login-button {
    width: 100%;
    margin-top: 1rem;
  }
}

.eyeIcon3 {
  position: absolute;
  top: 8rem;
  right: 5rem;
}

.eyeIcon4 {
  position: absolute;
  top: 12rem;
  right: 5rem;
}

.eyeIconn {
  position: relative;
    top: 3rem;
    right: 2rem;
}

.inputField {
  position: relative;
}

.errorMessage {
  position: absolute;
  bottom: -20px; /* Adjust this value as needed */
  left: 0;
  color: red;
  font-size: 0.75rem; /* Adjust the font size as needed */
}

.error {
  /* border: 1px solid red; */
  border-bottom: 1px solid red;
}
.error::placeholder {
  color: red;
}

.inputStyle.error {
  border-color: red; /* Add styles for error state */
}
.forgot-password:hover{
  color: green;
}
.login-modal-popup{
  width: 40rem;
}
.login-modal-popup .modal-content{
  width: 40rem;
}
.modal-dialog{
  justify-content: center;
}
@media screen and (max-width: 685px) {
  .login-modal-popup{
    width: 20rem;
  }
  .login-modal-popup .modal-content{
    width: 20rem;
  }
}
@media screen and (max-width: 346px) {
  .login-modal-popup{
    width: 15rem;
  }
  .login-modal-popup .modal-content{
    width: 15rem;
  }
}