.attendanceDetails_calendar {
  margin: 0 auto;
  max-width: 900px;
}

.attendanceDetails_footer {
  text-align: center;
}

.attendanceDetails_footerPresent {
  background-color: rgb(209, 255, 209);
  width: 24px;
  height: 24px;
  display: inline-block;
  /* border-radius: 50%; */
  border: 1px solid rgb(209, 255, 209);
  font-family: "GilroyMedium";
}

.attendanceDetails_footerAbsent {
  background-color: rgb(252, 229, 192);
  width: 24px;
  height: 24px;
  display: inline-block;
  /* border-radius: 50%; */
  font-family: "GilroyMedium";
  border: 1px solid rgb(252, 229, 192);
}

.attendanceDetails_footerLeaves {
  background-color: rgb(252, 242, 255);
  width: 24px;
  height: 24px;
  display: inline-block;
  /* border-radius: 50%; */
  border: 1px solid rgb(252, 242, 255);
  font-family: "GilroyMedium";
}

.attendanceDetails_footerOff {
  background-color: rgb(247, 247, 247);
  width: 40px;
  height: 24px;
  display: inline-block;
  border: 1px solid rgb(247, 247, 247);
  font-family: "GilroyMedium";
}

.attendanceDetails_holidayOff {
  background-color: rgb(255, 230, 230);
  width: 24px;
  height: 24px;
  display: inline-block;
  border: 1px solid rgb(247, 247, 247);
  font-family: "GilroyMedium";
}

.custom-event {
  /* width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; */
  color: #7f8fa4;
  font-size: 14px;
  font-family: "GilroyMedium";
  white-space: normal;
  word-wrap: break-word;
  /* text-align: center; */
  /* padding: 0 4px; */
  font-size: 0.85rem;
  /* Adjust as needed */
  line-height: 1.2;
}

.current-month {
  font-family: "GilroyBold";
  font-size: 16px;
}

.table-navigation-buttons {
  width: 5rem;
  font-family: "GilroyMedium";
  color: white !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  background: linear-gradient(90deg, #00a87e, #01d4a0) !important;
  border-radius: 20px !important;
  height: 2rem;
  border: none;
}

.rbc-header {
  font-family: "GilroyBold";
}

.rbc-date-cell {
  font-family: "GilroyMedium";
}

.legend-title {
  background-color: rgb(221, 241, 238);
  font-family: "GilroyMedium";
  padding: 1rem;
}

.all-title {
  font-family: "GilroyMedium";
}

.custom-event-title {
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  padding: 0 4px;
  font-size: 0.85rem;
  /* Adjust as needed */
  line-height: 1.2;
  /* Adjust as needed */
}
